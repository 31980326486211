import { ref, reactive, computed, onMounted } from "vue";
import * as API from "@/API/checking/spotChecking";
import useThousands from "@/utils/payment/useThousands";
import { toThousands } from "@/utils/payment/numberFormat";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useOverflowText from "@/utils/payment/useOverflowText";
import { downloadFromUrl } from "@/utils/payment/downloadFile";
type SearchParams = {
  id: string;
  packgeCode: string;
  programNo: string;
  claimNo: string;
  timeLine: string;
  priority: string;
  assginee: string;
};

export interface TableData {
  programId: string;
  programCode: string;
  programName: string;
  programPeriodFrom: string;
  to: string;
  dealerNo: string;
  vinNo: string;
  claimNo: string;
  spotCheckStatus: string;
  sampleCheckStatus: string;
  controllingCheckStatus: string;
}
export interface TableDataMBCL {
  payoutCode: string;
  programCode: string;
  programPeriodFrom: string;
  to: string;
  dealerNo: string;
  vinNo: string;
  claimNo: string;
  spotCheckStatus: string;
  sampleCheckStatus: string;
  controllingCheckStatus: string;
}

const useVehicleNameSet = () => {
  const { state, commit } = useStore();
  const router = useRouter();

  const searchParams = reactive<SearchParams>({
    id: "",
    packgeCode: "",
    programNo: "",
    claimNo: "",
    timeLine: "",
    priority: "",
    assginee: "",
  });
  const total = ref<number>(0);
  const pageNumber = ref<number>(0);
  const pageSize = ref<number>(10);
  const columnsData = computed(() => {
    return [
      { title: "Program Code", dataIndex: "programCode", width: 130 },
      {
        title: "Program Name",
        dataIndex: "programName",
        width: 270,
        customRender: useOverflowText(),
      },
      {
        title: "Program Period From",
        dataIndex: "programPeriodFrom",
        slots: { customRender: "programPeriodFrom" },
        width: 160,
      },
      {
        title: "To",
        dataIndex: "to",
        slots: { customRender: "to" },
        width: 80,
      },
      {
        title: "Dealer No.",
        dataIndex: "dealerNo",
        customRender: useThousands(),
        align: "right",
        width: 80,
      },
      {
        title: "VIN No.",
        dataIndex: "vinNo",
        customRender: useThousands(),
        align: "right",
        width: 80,
      },
      {
        title: "Claim No.",
        dataIndex: "claimNo",
        customRender: useThousands(),
        align: "right",
        width: 80,
      },
      {
        title: "Logic Check",
        dataIndex: "spotCheckStatus",
        slots: { customRender: "spotCheckStatus" },
        width: 120,
      },
      {
        title: "Sample Check",
        dataIndex: "sampleCheckStatus",
        slots: { customRender: "sampleCheckStatus" },
        width: 120,
      },
      {
        title: "Controlling Check",
        dataIndex: "controllingCheckStatus",
        slots: { customRender: "controllingCheckStatus" },
        width: 150,
      },
      {
        title: "Comment",
        dataIndex: "comment",
        slots: { customRender: "comment" },
        align: "center",
        width: 100,
      },
    ];
  });
  const tableData = ref<TableData[]>();
  const getTableData = () => {
    const params = {
      packageCodeId: searchParams.id,
      pageNumber: pageNumber.value,
      pageSize: pageSize.value,
    };
    API.fetchCheckProgramList(params).then((data: any) => {
      searchParams.programNo = toThousands(data.content[0].programNo);
      searchParams.claimNo = toThousands(data.content[0].totalClaimNo);
      searchParams.priority = data.content[0].priority;
      searchParams.assginee = data.content[0].assignee;
      const beginTime =
        typeof data.content[0].timeLineBegin === "string"
          ? data.content[0].timeLineBegin.substr(0, 10)
          : "";
      const endTime =
        typeof data.content[0].timeLineEnd === "string"
          ? data.content[0].timeLineEnd.substr(0, 10)
          : "";
      searchParams.timeLine =
        beginTime && endTime ? beginTime + " - " + endTime : "";
      tableData.value = data.content[0].spotCheckPackageCodeRespVOList;
      total.value = data.totalElements;
    });
  };
  const doSearch = () => {
    console.log("search按钮");
    pageNumber.value = 1;
    getTableData();
  };
  const doReset = () => {
    // Object.assign(searchParams, {
    //     packgeCode: '',
    //     programNo: '',
    //     claimNo: '',
    //     timeLine: '',
    //     priority: '',
    //     assginee: ''
    // })
    tableData.value = [];
    getTableData();
  };
  const pageChange = (page: number, size: number) => {
    pageNumber.value = page > 0 ? page : 1;
    pageSize.value = size;
    getTableData();
  };
  const sizeChange = (page: number, size: number) => {
    pageNumber.value = page > 0 ? page : 1;
    pageSize.value = size;
    getTableData();
  };
  const payoutRoundClick = (record: any) => {
    const info = {
      payoutRoundId: record.payoutRoundId,
      payoutRound: record.payoutRound,
      status: record.status,
    };
    console.log(info);
    commit("spotChecking/updatePayoutRoundData", info);
    router.push({ path: "/spotChecking/pendingApproval/payoutRound" });
  };
  onMounted(() => {
    searchParams.id = state.spotChecking.packageData.id + "";
    searchParams.packgeCode = state.spotChecking.packageData.packageCode;
    doSearch();
  });
  const searchParamsMBCL = reactive({
    id: "",
    payoutCode: "",
    programNo: "",
    VinNo: "",
    timeLine: "",
    priority: "",
    assginee: "",
  });
  const columnsMBCLData = computed(() => {
    return [
      { title: "Program Code", dataIndex: "programCode" },
      { title: "Program Name", dataIndex: "programName" },
      {
        title: "Program Period From",
        dataIndex: "programPeriodFrom",
        slots: { customRender: "programPeriodFrom" },
      },
      { title: "To", dataIndex: "to", slots: { customRender: "to" } },
      {
        title: "Dealer No.",
        dataIndex: "dealerNo",
        customRender: useThousands(),
        align: "right",
      },
      {
        title: "VIN No.",
        dataIndex: "vinNo",
        customRender: useThousands(),
        align: "right",
      },
      {
        title: "Claim No.",
        dataIndex: "claimNo",
        customRender: useThousands(),
        align: "right",
      },
      { title: "Spot Check", dataIndex: "spotCheckStatus" },
      {
        title: "Sample Check",
        dataIndex: "sampleCheckStatus",
        align: "center",
      },
      { title: "Controlling Check", dataIndex: "controllingCheckStatus" },
      {
        title: "Comment",
        dataIndex: "comment",
        slots: { customRender: "comment" },
      },
    ];
  });
  const tableDataMBCL = ref<TableDataMBCL[]>();

  //#region comment
  const commentVisible = ref(false);
  const commentData = ref();
  const commentClick = (record: TableData) => {
    const params = {
      packageCodeId: searchParams.id,
      programId: record.programId,
    };
    API.fetchCheckCommentList(params).then((data) => {
      commentData.value = data;
      commentVisible.value = true;
    });
  };
  //#endregion

  const handleDownloadSourceData = async () => {
    const res = await API.downloadSourceData(searchParams.id);
    downloadFromUrl(res.data.data);
  };

  return {
    searchParams,
    searchParamsMBCL,
    columnsData,
    columnsMBCLData,
    tableData,
    tableDataMBCL,
    doSearch,
    doReset,
    pageChange,
    sizeChange,
    pageSize,
    pageNumber,
    total,
    payoutRoundClick,
    commentVisible,
    commentData,
    commentClick,
    handleDownloadSourceData,
  };
};
export default useVehicleNameSet;
